import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppGuard} from './app.guard';
import {AuthGuard} from '@auth/auth.guard';
import {AppLayoutComponent} from '@shared-modules/layouts/app-layout/app-layout.component';
import {MyNetworkErrorComponent} from './chat-errors/my-network-error/my-network-error.component';
import {
  PatientNetworkErrorComponent
} from './chat-errors/patient-network-error/patient-network-error.component';
import {
  PrivacyPolicyComponent
} from '@shared-modules/terms-and-privacy/privacy-policy/privacy-policy.component';
import {TermsComponent} from '@shared-modules/terms-and-privacy/terms/terms.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsComponent
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'wizard',
        data: {isHeaderShortened: true, isSidebarHidden: true},
        loadChildren: () => import('./dashboard/wizard/wizard.module').then((m) => m.WizardModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./dashboard/home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'consultations',
        loadChildren: () =>
          import('./dashboard/consultations/consultations.module').then(
            (m) => m.ConsultationsModule
          )
      },
      {
        path: 'orders',
        loadChildren: () => import('./dashboard/orders/orders.routes').then(
          (r) => r.ORDER_ROUTES
        )
      },
      {
        path: 'my-clients',
        loadChildren: () =>
          import('./dashboard/patients/patients.module').then((m) => m.PatientsModule)
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./dashboard/schedule/schedule.module').then((m) => m.ScheduleModule)
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./dashboard/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'profile-settings',
        loadChildren: () =>
          import('./dashboard/profile-settings/profile-settings.module').then((m) => m.ProfileSettingsModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./dashboard/support/support.module').then((m) => m.SupportModule)
      },
      {
        path: 'dialogues',
        loadChildren: () =>
          import('./dashboard/dialogues/dialogues.module').then((m) => m.DialoguesModule)
      },
      {
        path: 'my-services',
        loadChildren: () => import('./dashboard/my-services/my-services.routes').then(m => m.MY_SERVICES_ROUTES)
      },
      {
        path: 'course',
        data: {isSidebarHidden: true, isHeaderShortened: true},
        loadChildren: () => import('./dashboard/my-services/features/courses/features/course/course.routes').then(m => m.COURSE_ROUTES)
      },
      {
        path: 'all-documents',
        loadChildren: () => import('./dashboard/all-documents/all-documents.module').then(m => m.AllDocumentsModule)
      },
      {
        path: 'recipes',
        loadChildren: () => import('./dashboard/recipes/recipes.routes').then(m => m.RECIPES_ROUTES)
      },
      {
        path: 'activities',
        loadChildren: () => import('./dashboard/activity-builders-libraries/activity-builders-libraries.routes').then(r => r.ACTIVITY_BUILDERS_LIBRARIES)
      },
      {
        path: 'recipes-manage',
        data: {
          isSidebarHidden: true,
          isHeaderShortened: true
        },
        loadComponent: () => import('./dashboard/recipes/features/manage-recipe/manage-recipe.component').then(c => c.ManageRecipeComponent)
      },
      {
        path: 'recipes-manage/:id',
        data: {
          isSidebarHidden: true,
          isHeaderShortened: true
        },
        loadComponent: () => import('./dashboard/recipes/features/manage-recipe/manage-recipe.component').then(c => c.ManageRecipeComponent)
      },
      {
        path: 'edit-meal-plan-template/:recommendationTemplateId/:consultationId',
        data: {
          isSidebarHidden: true,
          isHeaderShortened: true
        },
        loadComponent: () => import('./dashboard/consultation-result/edit-meal-plan-template/edit-meal-plan-template.component').then(c => c.EditMealPlanTemplateComponent)
      },
      {
        path: 'meal-plan-manage',
        data: {
          isSidebarHidden: true,
          isHeaderShortened: true
        },
        loadComponent: () => import('./dashboard/recipes/features/manage-meal-plan/manage-meal-plan.component').then(c => c.ManageMealPlanComponent)
      },
      {
        path: 'meal-plan-manage/:id',
        data: {
          isSidebarHidden: true,
          isHeaderShortened: true
        },
        loadComponent: () => import('./dashboard/recipes/features/manage-meal-plan/manage-meal-plan.component').then(c => c.ManageMealPlanComponent)
      },
      {
        path: 'template',
        data: {
          useEmptyHeader: true,
          isSidebarHidden: true
        },
        loadChildren: () => import('./dashboard/all-documents/features/templates/components/template-editor/template-editor.routes')
          .then(m => m.TEMPLATE_ROUTES)
      },
      {
        path: 'clinics',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./clinics/clinics.module').then((m) => m.ClinicsModule)
      },
      {
        path: 'questionnaire',
        data: {
          useEmptyHeader: true,
          isSidebarHidden: true,
          isNewNotificationsHidden: true,
        },
        loadChildren: () => import('./dashboard/questionnaire/questionnaire.routes').then(m => m.QUESTIONAIRE_ROUTES)
      },
      {
        path: 'chat/:id',
        data: {isHeaderShortened: true, isSidebarHidden: true, isNewNotificationsHidden: true},
        loadChildren: () => import('./chat-room/chat-room.module').then((m) => m.ChatRoomModule)
      },
      {
        path: 'chat-history/:id',
        loadChildren: () =>
          import('./chat-history/chat-history.module').then((m) => m.ChatHistoryModule)
      },
      {
        path: 'consultation-result/:id',
        data: {
          useEmptyHeader: true,
          isSidebarHidden: true,
          isNewNotificationsHidden: true,
          headerTitle: 'Рекомендації для клієнта',
          backRoute: '/consultations/past'
        },
        loadComponent: () => import('./dashboard/consultation-result/consultation-result.component').then(c => c.ConsultationResultComponent)
      },
      {
        path: 'order-result/:id',
        data: {
          isSidebarHidden: true,
          useEmptyHeader: false
        },
        loadChildren: () => import('./dashboard/order-result/order-result.routes').then(m => m.ORDER_RESULT_ROUTES)
      },
      {
        path: 'consultation-result-preview/:id',
        data: {
          useEmptyHeader: true,
          isSidebarHidden: true,
          isNewNotificationsHidden: true,
          headerTitle: 'Рекомендація'
        },
        loadComponent: () => import('./dashboard/consultation-result/consultation-result-preview/consultation-result-preview.component').then(c => c.ConsultationResultPreviewComponent)
      },
      {
        path: 'patient-network-error/:id',
        data: {isHeaderShortened: true, isSidebarHidden: true, isNewNotificationsHidden: true},
        component: PatientNetworkErrorComponent
      },
      {
        path: 'my-network-error/:id',
        canActivate: [AppGuard],
        component: MyNetworkErrorComponent
      },
      {
        path: 'not-found',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
      },
      {
        path: '**',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
